import React, { useEffect, useState, useRef } from 'react'
import { apiDriverApp } from '../../../../libs/apiDriverApp'
import LoadMask from '../../Utils/LoadMask';
import Select from "react-select";
import './Forms.css'
import { DatesModal } from '../components/forms/DatesModal';
import IconDownload from '../../../icons/download';
import { DownloadModal } from '../components/forms/DownloadModal';
import { DetailsModal } from '../components/forms/DetailsModal';
import IconSearch from '../../../icons/search';
import { loadMainVehicles } from '../../../../redux/modules/expenseTickets';

export const FormsView = () => {

    const [showDetails, setShowDetails] = useState(false);
    const [filters, setFilters] = useState({
        form: '',
        station: '',
        date: '',
        vehicle: '',
        employee: '',
        search:'',
        unsafe:false
        
    });
    const [isChecked, setIsChecked] = useState(false);
    const [data, setData] = useState(null);
    const [showDlModal, setShowDlModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [forms, setForms] = useState([]);
    const [stations, setStations] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [drivers, setDrivers] = useState([])
    const [selected, setSelected] = useState(null)
    const [stats, setStats] = useState({
        total: 0,
        weekly: 0,
        percentage: 0,
        unsafe: 0
    })
    const [formValues, setFormValues] = useState({
        startDate: null,
        endDate: null,
    });
    const listRef = useRef(null);
    const searchRef = useRef();

    const handleSearch = () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            search: searchRef.current.value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
    
        setFilters(prevFilters => {
            const newFilters = { ...prevFilters };
            if (isChecked) {
                newFilters.unsafe_conditions_only = true; 
            } else {
                delete newFilters.unsafe_conditions_only;
            }
            return newFilters;
        });
    };

    const handleChange = (name, value) => {
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === "date" && value === "custom") {
            setShowModal(true); 
        }
    };

    const getBoundaries = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();

        const firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

        const firstDayLastWeek = new Date(firstDayOfWeek);
        firstDayLastWeek.setDate(firstDayOfWeek.getDate() - 7);

        const lastDayLastWeek = new Date(lastDayOfWeek);
        lastDayLastWeek.setDate(lastDayOfWeek.getDate() - 7);

        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return {
            firstDayOfWeek: firstDayOfWeek.toISOString().split('T')[0],
            lastDayOfWeek: lastDayOfWeek.toISOString().split('T')[0],
            firstDayLastWeek: firstDayLastWeek.toISOString().split('T')[0],
            lastDayLastWeek: lastDayLastWeek.toISOString().split('T')[0],
            firstDayOfMonth: firstDayOfMonth.toISOString().split('T')[0],
            lastDayOfMonth: lastDayOfMonth.toISOString().split('T')[0],
            today: today.toISOString().split('T')[0],
        };
    }

    useEffect(() => {
        if (filters.date === "custom" && (!formValues.startDate || !formValues.endDate)) {
            return
        }
        getFormResponses(1, filters)
    }, [filters, formValues])

    const getDrivers = async () => {
        try {
            const { data } = await apiDriverApp.get('api/hr/employees/');
            const optionDrivers = [
                { label: 'None', value: 'none' },
                ...data.map(item => ({
                    label: `${item.user.first_name} ${item.user.last_name} (${item.user.username})` || '',
                    value: item.id,
                }))]
            setDrivers(optionDrivers)
        } catch (error) {
            console.log(error)
        }
    }

    const getStats = async () => {
        const dates = getBoundaries()
        try {
            const { data } = await apiDriverApp.get('/api/forms/responses/?simple_format=false')
            const { data: lastWeek } = await apiDriverApp.get(`/api/forms/responses/?simple_format=false&start_date=${dates.firstDayLastWeek}&end_date${dates.lastDayLastWeek}`)
            const { data: thisWeek } = await apiDriverApp.get(`/api/forms/responses/?simple_format=false&start_date=${dates.firstDayOfWeek}&end_date${dates.lastDayOfWeek}`)
            const perWeek = (lastWeek.count / 100) * thisWeek.count

            setStats({
                total: data.count,
                weekly: thisWeek.count,
                percentage: `${lastWeek.count > thisWeek.count ? '-' : '+'} ${Math.floor(perWeek)}%`,
                unsafe: thisWeek.results.summary.unsafe_vehicle_count
            })
        } catch (error) {
            console.log(error)
        }
    }

    const scrollToTop = () => {
        if (listRef.current) {
            listRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const getFormResponses = async (page = 1, filters = {}) => {
        setIsLoading(true)

        let baseUrl = `/api/forms/responses/`;
        const params = new URLSearchParams({ page, simple_format: "false" });

        Object.keys(filters).forEach((key) => {
            if (filters[key] && filters[key] !== "none") {
                if (key === "date") {
                   
                    const dates = getBoundaries()
                    if (filters.date === "today") {
                        params.append("start_date", dates.today);
                        params.append("end_date", dates.today);
                    } else if (filters.date === "week") {
                        params.append("start_date", dates.firstDayOfWeek);
                        params.append("end_date", dates.lastDayOfWeek);
                    } else if (filters.date === "month") {
                        params.append("start_date", dates.firstDayOfMonth);
                        params.append("end_date", dates.lastDayOfMonth);
                    } else if (filters.date === "custom" && formValues.startDate && formValues.endDate) {
                        params.append("start_date", formValues.startDate);
                        params.append("end_date", formValues.endDate);
                    }
               
                } else {
                    params.append(key, filters[key]);
                }
            }
        });

        const url = `${baseUrl}?${params.toString()}`;

        try {
            const { data } = await apiDriverApp.get(url);
            setData(data.results);
            setNextPage(data.next);
            setPrevPage(data.previous);
            setTotalPages(Math.ceil(data.count / 20));
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const getForms = async () => {
        try {
            const { data } = await apiDriverApp.get('api/forms/');
            const flVehicles = await loadMainVehicles();
            const formatted = flVehicles.options.map(item=>({
                label:item.label,
                value:item.label
            }))
            setVehicles(formatted)
         
            const forms = [
                { label: 'None', value: 'none' },
                ...data.map(item => ({
                    label: item.name,
                    value: item.id
                }))
            ];
            setForms(forms)
        } catch (error) {
            console.log(error)
        }
    }

    const getStations = async () => {
        try {
            const { data } = await apiDriverApp.get('api/hr/stations/');
            const stations = [
                { label: 'None', value: 'none' },
                ...data.map(item => ({
                    label: item.name,
                    value: item.name
                }))]
            setStations(stations)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;

        setCurrentPage(page);
        getFormResponses(page, filters)
        scrollToTop()
    };

    const getPageNumbers = () => {
        let start = Math.max(1, currentPage - 2);
        let end = Math.min(totalPages, start + 4);
        if (end - start < 4) start = Math.max(1, end - 4);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    const dateOptions = [
        { label: 'None', value: 'none' },
        { label: 'Today', value: 'today' },
        { label: 'This Week', value: 'week' },
        { label: 'This Month', value: 'month' },
        { label: 'Custom Range', value: 'custom' },
    ];

    const filtersConfig = [
        { id: 'select1', key: 'form', label: 'All Forms', options: forms },
        { id: 'select2', key: 'station', label: 'All Stations', options: stations },
        { id: 'select3', key: 'date', label: 'Date Range', options: dateOptions },
        { id: 'select4', key: 'employee', label: 'Employee', options: drivers },
        { id: 'select5', key: 'vehicle', label: 'Vehicle', options: vehicles },
    ];

    const handleCloseDetails = ()=>{
       setSelected(null) 
       setShowDetails(false)
    }

    useEffect(()=>{
    if (selected !== null) {
        setShowDetails(true);
    }
    },[selected])

    useEffect(() => {
        getFormResponses();
        getStations();
        getForms();
        getDrivers();
        getStats()
    }, [])

    return (
        <React.Fragment>
            <div className='custom-container'>
                <LoadMask loading={isLoading} blur>

                    <div className='button-bar'>
                        <h2 className='text-left font-bold'>Form Submissions</h2>
                        <div className="download-upload-buttons">
                            <button className='btn-hover' onClick={() => setShowDlModal(true)}>
                                <div className='row'>
                                    <div style={{ height: '1rem' }}>
                                        <IconDownload className="h-100" />
                                    </div>
                                    <div style={{ marginLeft: 5, fontSize: 14 }}>Download Form submission report</div>
                                </div>
                            </button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className='align-items-center my-search-input'>
                            <div className='mb-2 custom-bar'>
                                <input
                                    placeholder="Search form submissions..."
                                    className="form-control"
                                    ref={searchRef}
                                />
                                <button
                                    className="btn rounded shadow-sm d-flex flex-row align-items-center ml-1"
                                    style={{ backgroundColor: '#28a745' }}
                                    onClick={handleSearch}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', height: '1.2rem', color:'white'}}>
                                        <IconSearch className='h-100' />
                                    </div>
                                </button>
                            </div>

                            <div className='filter-group' >
                                {
                                    filtersConfig.map(({ id, key, label, options }) => (
                                        <div key={id} className='select-dropdown'>
                                            <Select
                                                options={options}
                                                value={options.find((option) => option.value === filters[key])}
                                                onChange={(option) => handleChange(key, option.value)}
                                                placeholder={label}
                                                clearable={false}
                                                // searchable={false}
                                            />
                                        </div>

                                    ))


                                }
                                <div className="filter-toggle">
                                    <input
                                        type="checkbox"
                                        id="unsafe-filter"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="unsafe-filter">Show only unsafe vehicle conditions</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='mb-5' ref={listRef}>

                        <div className="dashboard-grid">
                            <div className="summary-card">
                                <div className="summary-title">Total Submissions</div>
                                <div className="summary-value">{stats.total}</div>
                                <div className="summary-label">All time</div>
                            </div>

                            <div className="summary-card">
                                <div className="summary-title">Submissions This Week</div>
                                <div className="summary-value">{stats.weekly}</div>
                                <div className="summary-label">{stats.percentage} from last week</div>
                            </div>

                            <div className="summary-card">
                                <div className="summary-title">Unsafe Conditions</div>
                                <div className="summary-value">{stats.unsafe}</div>
                                <div className="summary-label">Reported this week</div>
                            </div>
                        </div>

                        <h3 className='font-bold text-left my-5'>Recent Submissions</h3>

                        {
                            data && data.responses ? data.responses.map(item => (
                                <div className="submission-card" onClick={()=>setSelected(item.id)} key={item.id} style={{ borderLeft: `4px solid ${item.employee ? item.employee.department_color : 'black'}` }}>
                                    <div className="submission-header">
                                        <div className="submission-title">
                                            {item.form.name}
                                        </div>
                                        <span className="station-badge" style={{
                                            backgroundColor: item.employee.department_color
                                        }}>{item.employee.station_code}</span>
                                    </div>

                                    <div className="submission-meta">
                                        <div className="submission-meta-item">
                                            <span className="meta-label">Employee</span>
                                            <span className="meta-value">{`${item.employee.first_name} ${item.employee.last_name} (${item.employee.username})`}</span>
                                        </div>
                                        <div className="submission-meta-item">
                                            <span className="meta-label">Vehicle</span>
                                            <span className="meta-value">{item.vehicle}</span>
                                        </div>
                                        <div className="submission-meta-item">
                                            <span className="meta-label">Date Submitted</span>
                                            <span className="meta-value">{item.date_submitted.split('T')[0]}</span>
                                        </div>
                                        <div className="submission-meta-item">
                                            <span className="meta-label">Form ID</span>
                                            <span className="meta-value">{item.id}</span>
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                <div className='d-flex justify-content-center'>
                                    NO DATA
                                </div>
                            )
                        }

                        <div className="pagination">
                            <button disabled={!prevPage} onClick={() => handlePageChange(currentPage - 1)}>{"<<"}</button>
                            {getPageNumbers().map((num) => (
                                <button
                                    key={num}
                                    className={num === currentPage ? "active" : ""}
                                    onClick={() => handlePageChange(num)}
                                >
                                    {num}
                                </button>
                            ))}
                            <button disabled={!nextPage} onClick={() => handlePageChange(currentPage + 1)}>{">>"}</button>
                        </div>
                    </div>
                </LoadMask>
            </div>
            <DatesModal
                showModal={showModal}
                handleCloseModal={() => setShowModal(false)}
                handleChange={setFormValues}
            />
            <DownloadModal
                showModal={showDlModal}
                handleCloseModal={() => setShowDlModal(false)}
            />
           {
            selected && showDetails && (
                <DetailsModal
                showModal={showDetails}
                handleCloseModal={handleCloseDetails}
                formId={selected}
              />
            )
           }
        </React.Fragment>

    )
}
