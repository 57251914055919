import React, { useEffect, useState } from 'react'
import Modal from "react-responsive-modal";
import './Details.css'
import { getFormDetails } from '../../utils/forms';
import { pdf } from '@react-pdf/renderer';
import FormPDFdoc from './FormPDFdoc';

export const DetailsModal = ({ showModal, handleCloseModal, formId }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    const getData = async () => {
      const details = await getFormDetails(formId);
      setData(details)
    }

    getData()
  }, [formId]);

  const handleDownload = async () => {
    const blob = await pdf(<FormPDFdoc data={data} />).toBlob();
    saveAs(blob, `Form_report_${formId}.pdf`);
  };



  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      blockScroll={false}
    // classNameNames={{ modal: "modal-sm" }}
    >
      <span className='text-left font-bold form-title'>{data && data.form ? (data.form.name) : 'Loading...'}</span>
      <hr />

      {
        data && (
          <React.Fragment>
            <div className="form-info">
              <div className="form-info-item">
                <div className="form-info-label">Station</div>
                <div className="form-info-value"><span className="formbadge" style={{ backgroundColor: data.employee.department_color }}>{data.employee.station_code}</span></div>
              </div>
              <div className="form-info-item">
                <div className="form-info-label">Employee</div>
                <div className="form-info-value">{`${data.employee.first_name} ${data.employee.last_name} (${data.employee.username})`}</div>
              </div>
              <div className="form-info-item">
                <div className="form-info-label">Vehicle</div>
                <div className="form-info-value">{data.vehicle}</div>
              </div>
              <div className="form-info-item">
                <div className="form-info-label">Submission Date</div>
                <div className="form-info-value">{data.date_submitted.split('T')[0]}</div>
              </div>
              {
                data.values.some(item => item.value_text === 'Not safe to operate') ? (
                  <div className="form-info-item">
                    <div className="form-info-label">Vehicle Condition</div>
                    <div className="form-info-value"><span className="form-status status-unsafe">UNSAFE</span></div>
                  </div>
                ) : (
                  <div className="form-info-item">
                    <div className="form-info-label">Vehicle Condition</div>
                    <div className="form-info-value"><span className="form-status status-safe">SAFE</span></div>
                  </div>
                )
              }

            </div>

            <div className="form-section">
              {
                data.values.map((item, index) => {
                  if (item.field.name === 'Remarks' || item.field.name === 'Additional comments?') {
                    return (
                      <div className="form-field" key={index}>
                        <div className="form-field-label">{item.field.name}</div>
                        <div className="form-field-value textarea">{item.field.value_text ? item.field.value_text : 'No comments added'}</div>
                      </div>
                    );
                  }

                  return (
                    <div className="form-field" key={index}>
                      <div className="form-field-label">{item.field.name}</div>
                      <div className="form-field-description">{item.field.description !== 'NULL' ? item.field.description : ''}</div>

                      {item.field.name === 'Odometer Reading' ?
                        <div className="result-label result-pass">{`${parseInt(item.value_text, 10).toLocaleString()}mi.`}</div>
                        : <div className={`result-label ${(item.value_text === 'Fail' || item.value_text === 'Not safe to operate') ? 'result-fail' : 'result-pass'}`}>{item.value_text ? item.value_text : 'No response'}</div>
                      }

                      {
                        item.remarks && (
                          <React.Fragment>
                            <div className="mt-2">Additional Notes:</div>
                            <div className="form-field-value textarea">{item.remarks}</div>
                          </React.Fragment>
                        )
                      }
                    </div>
                  );
                })
              }
            </div>
          </React.Fragment>

        )
      }

      <div className="modal-footer">
        <button className="form-action-button cancel-button" onClick={handleCloseModal}>Close</button>
        <button className="form-action-button primary-button" onClick={handleDownload}>Download PDF</button>
      </div>
    </Modal>
  )
}
