import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: { padding: 20 },
    section: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, borderBottom: '1 solid #ccc', paddingBottom: 10 },
    title: { fontSize: 18, fontWeight: 'bold', marginBottom: 10 },
    label: { fontSize: 12, fontWeight: 'bold', marginBottom: 4 },
    value: { fontSize: 12, marginBottom: 4 },
    fieldContainer: { marginBottom: 10 },
    row: { flexDirection: 'row', justifyContent: 'space-between' },
    remarks: { marginTop: 5, fontSize: 12, fontStyle: 'italic' }
  });

const FormPDFdoc = ({ data }) => (
    <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>{data.form.name}</Text>

      <View style={styles.section}>
        <View>
          <Text style={styles.label}>Station:</Text>
          <Text style={styles.value}>{data.employee.station_code}</Text>
        </View>
        <View>
          <Text style={styles.label}>Employee:</Text>
          <Text style={styles.value}>{`${data.employee.first_name} ${data.employee.last_name} (${data.employee.username})`}</Text>
        </View>
        <View>
          <Text style={styles.label}>Vehicle:</Text>
          <Text style={styles.value}>{data.vehicle}</Text>
        </View>
        <View>
          <Text style={styles.label}>Submission Date:</Text>
          <Text style={styles.value}>{data.date_submitted.split('T')[0]}</Text>
        </View>
      </View>

      {data.values.map((item, index) => (
        <View key={index} style={styles.fieldContainer}>
          <Text style={styles.label}>{item.field.name}</Text>
          <Text style={styles.value}>
            {item.field.name === 'Odometer Reading' ? `${parseInt(item.value_text, 10).toLocaleString()} mi.` : item.value_text}
          </Text>
          {item.remarks && <Text style={styles.remarks}>Additional Notes: {item.remarks}</Text>}
        </View>
      ))}
    </Page>
  </Document>
);

export default FormPDFdoc;
