import { saveAs } from 'file-saver';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

export const downloadFormSubmissionReport = async (form_id, start_date, end_date) => {
    const params = { form_id };
    if (start_date) params.start_date = start_date;
    if (end_date) params.end_date = end_date;

    try {
        const response = await apiDriverApp.get('/api/forms/report/download/',{
            params,
            responseType: 'blob', 
        });

        if (!response.data) {
            console.warn("No data received from backend.");
            return;
        }
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'report.xlsx'; 
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?([^"]+)"?/);
            if (match) {
                filename = match[1];
            }
        }
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    } catch (error) {
        console.error("Error downloading form submission report", error);
    }
};

export const getFormDetails = async (form_id)=>{
    try {
        const {data} = await apiDriverApp.get(`/api/forms/responses/${form_id}/`)
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}