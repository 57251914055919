import React, { useState } from 'react'
import Modal from "react-responsive-modal";
import { SingleDatePicker } from 'react-dates';
import { downloadFormSubmissionReport } from '../../utils/forms'


export const DownloadModal = ({ showModal, handleCloseModal }) => {
    const [focused, setFocused] = useState(false);
    const [focused2, setFocused2] = useState(false);

    const [formValues, setFormValues] = useState({
        form: '',
        startDate: null,
        endDate: null,
    });

    const closeModal = ()=>{
        setFormValues({
            form: '',
            startDate: null,
            endDate: null,
        })
        setError(false)
        handleCloseModal()
    }

    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (!formValues.form) {
            setError(true)
            return
        }
        try {
            const start = formValues.startDate && formValues.startDate.format('YYYY-MM-DD')
            const end = formValues.endDate && formValues.endDate.format('YYYY-MM-DD')
            downloadFormSubmissionReport(formValues.form, start, end)
        } catch (error) {
            console.log(error)
        } finally {
            setFormValues({
                form: '',
                startDate: null,
                endDate: null,
            })
            handleCloseModal()
        }
    }

    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            showCloseIcon={false}
            classNames={{ modal: "modal-sm" }}
            blockScroll={false}
        >
            <div className="d-flex flex-column">
                <div className="mb-2">
                    <label>Form Type:</label>
                    <select
                        className="form-control"
                        value={formValues.form}
                        onChange={(e) => setFormValues(prev => ({
                            ...prev,
                            form: e.target.value,
                        }))}
                    >
                        <option value="">
                            Form type
                        </option>
                        <option value={100}>
                            CDL Pre-Trip Inspection
                        </option>
                        <option value={101}>
                            Car Wash Form
                        </option>
                        <option value={102}>
                            Standard Pre-Trip Inspection
                        </option>
                    </select>
                    {error && <span style={{color:'red'}}>Required field</span>}
                </div>
                <div className="mb-2">
                    <label>Start Date:</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input"
                            date={formValues.startDate}
                            onDateChange={(newDate) => setFormValues(prev => ({
                                ...prev,
                                startDate: newDate,
                            }))}
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            placeholder='2025-01-01'
                        />

                    </div>
                </div>
                <div className="mb-2">
                    <label>End Date:</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input2"
                            date={formValues.endDate}
                            onDateChange={(newDate) =>
                                setFormValues(prev => ({
                                    ...prev,
                                    endDate: newDate,
                                }))
                            }
                            focused={focused2}
                            onFocusChange={({ focused }) => setFocused2(focused)}
                            numberOfMonths={1}
                            isOutsideRange={(day) =>
                                formValues.startDate ? day.isBefore(formValues.startDate, 'day') : false
                            }
                            placeholder='2025-01-02'
                        />
                    </div>
                </div>
                <div className="d-flex flex-row mt-4 justify-content-between">
                    <button
                        className="btn btn-secondary"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    )
}
