import React, { useState } from 'react'
import Modal from "react-responsive-modal";
import { SingleDatePicker } from 'react-dates';


export const DatesModal = ({ showModal, handleCloseModal, handleChange }) => {
    const [focused, setFocused] = useState(false);
    const [focused2, setFocused2] = useState(false);

    const [formValues, setFormValues] = useState({
        startDate: null,
        endDate: null,
    });

    const handleSubmit = ()=>{
        handleChange({
            startDate: formValues.startDate.format('YYYY-MM-DD'),
            endDate: formValues.endDate.format('YYYY-MM-DD')
        })
        handleCloseModal()
    }

    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            showCloseIcon={false}
            classNames={{ modal: "modal-sm" }}
        >
            <div className="d-flex flex-column">
                <div className="mb-2">
                    <label>Start Date</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input"
                            date={formValues.startDate}
                            onDateChange={(newDate) => setFormValues(prev => ({
                                ...prev, 
                                startDate: newDate, 
                            }))}
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            placeholder='2025-01-01'
                        />

                    </div>
                </div>
                <div className="mb-2">
                    <label>End Date</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input2"
                            date={formValues.endDate}
                            onDateChange={(newDate) =>
                                setFormValues(prev => ({
                                    ...prev,
                                    endDate: newDate,
                                }))
                            }
                            focused={focused2}
                            onFocusChange={({ focused }) => setFocused2(focused)}
                            numberOfMonths={1}
                            isOutsideRange={(day) =>
                                formValues.startDate ? day.isBefore(formValues.startDate, 'day') : false
                            }
                            placeholder='2025-01-02'
                        />
                    </div>
                </div>
                <div className="d-flex flex-row mt-4 justify-content-between">
                    <button
                        className="btn btn-secondary"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    )
}
