import React, { useEffect, useState, useRef } from 'react'
import IconWatch from '../../../icons/watch'
import { apiDriverApp } from '../../../../libs/apiDriverApp'
import IconDownload from '../../../icons/download'
import IconUpload from '../../../icons/upload'
import ArchiveIcon from '../../../icons/archive-icon'
import IosIcon from '../../../icons/ios-icon'
import AndroidIcon from '../../../icons/android-icon'
import SortIcon from '../../../icons/sort-icon'
import RotateArrow from '../../../icons/rotate-arrow'
import { downloadDriverList, onFileUpload, getFullName } from '../utils/drivers'
import { ModalDriverForm } from '../components/ModalDriverForm'
const moment = require('moment');
import './Drivers.css'
import LoadMask from '../../Utils/LoadMask'
import { ConfirmationModal } from '../components/ConfirmationModal'

export const Drivers = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [inactiveDrivers, setInactiveDrivers] = useState([])
  const [stations, setStations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null)
  const [search, setSearch] = useState("");
  const fileInputRef = useRef();
  const [jobs, setJobs] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [filters, setFilters] = useState({
    station: '',
    schedule: '',
    login: '',
  });

  const [stats, setStats] = useState({
    totalDrivers: 0,
    totalActive: 0,
    totalUnassigned: 0,
    mostActive: 'No Data',
    activeDrivers: 0
  });

  const scheduleOptions = [
    { label: 'Assigned', value: 'assigned' },
    { label: 'Unassigned', value: 'unassigned' },
  ];

  const LoginOptions = [
    { label: 'Active (Last 7 days)', value: 'active' },
    { label: 'Inactive (No login > 7 days)', value: 'inactive' },
    { label: 'Unknown', value: 'unknown' },

  ];

  const handleToggle = () => {
    setIsChecked(prev => !prev);
  };

  const getStations = async () => {
    try {
      const { data } = await apiDriverApp.get('api/hr/stations/');
      const stations = data.map(item => ({
        label: item.name,
        value: item.code
      }))
      setStations(stations)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const filtersConfig = [
    { id: 'select1', key: 'station', label: 'All Stations', options: stations },
    { id: 'select2', key: 'schedule', label: 'All Schedules', options: scheduleOptions },
    { id: 'select3', key: 'login', label: 'All Login Status', options: LoginOptions },
  ];

  const showConfirmationModal = () => {
    setShowConfirmation(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  };

  const getDrivers = async () => {
    setLoading(true)
    try {
      const { data } = await apiDriverApp.get('api/hr/employees/?active_status=all');
      const active = data.filter(item => item.active === 1);
      const inactive = data.filter(item => item.active === 0);
      setInactiveDrivers(inactive)
      const scheduled = active.filter((item) => item.resource.calendar)
      const unscheduled = active.filter((item) => item.resource && item.resource.calendar === null)
      const stationCounts = active.reduce((acc, item) => {
        if (item.station && item.station.name) {
          const stationName = item.station.name;
          acc[stationName] = (acc[stationName] || 0) + 1;
        }
        return acc;
      }, {});
      const maxStation = Object.entries(stationCounts).reduce((max, [name, count]) => {
        return count > max.count ? { name, count } : max;
      }, { name: null, count: 0 });
      setDrivers(active)
      setFilteredData(active)
      setStats({
        totalDrivers: active.length,
        totalActive: scheduled.length,
        totalUnassigned: unscheduled.length,
        mostActive: maxStation.name,
        activeDrivers: maxStation.count
      })
      // setIsChecked(false)
      setLoading(false)
      return data;
    } catch (error) {
      console.error("Error fetching drivers:", error);
      setLoading(false)
      throw error;
    }
  }

  const getAllschedules = async () => {
    const actualDate = moment().format('YYYY-MM-DD')
    try {
      const { data } = await apiDriverApp.get(`api/hr/schedule/get_all_driver_schedules/?start_date=${actualDate}`);
      const options = [
        { id: -3, value: 'None', label: 'None' },
        ...data.map(schedule => ({
          id: schedule.id,
          value: schedule.name,
          label: schedule.name,
          drivers: schedule.drivers
        }))
      ];
      setSchedules(options);
      return options;
    } catch (error) {
      console.error("Error fetching schedules:", error);
      throw error;
    }
  };

  const getJobs = async () => {
    try {
      const { data } = await apiDriverApp.get('api/hr/jobs');
      const jobs = data.filter(job => job.active == true);
      const jobOptions = jobs.map(job => ({
        value: job.name,
        label: job.name
      }))
      setJobs(jobOptions)
      return jobOptions;
    } catch (error) {
      console.error("Error fetching jobs:", error);
      throw error;
    }
  }

  const isLastLoginWithin7Days = (lastLogin) => {
    const lastLoginDate = new Date(lastLogin);
    const currentDate = new Date();
    const differenceInTime = currentDate - lastLoginDate;
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return differenceInDays <= 7;
  };

  useEffect(() => {
    getDrivers();
    getJobs();
    getAllschedules();
    getStations()
  }, [])

  useEffect(() => {
    // Dependiendo de isChecked, filtramos drivers o inactiveDrivers
    let filtered = isChecked ? [...inactiveDrivers] : [...drivers];
  
    if (filters.station) {
      filtered = filtered.filter((item) => 
        item.station !== null && item.station.code === filters.station
      );
    }
  
    if (filters.schedule === 'unassigned') {
      filtered = filtered.filter((item) => 
        item.resource && item.resource.calendar === null
      );
    }
  
    if (filters.schedule === 'assigned') {
      filtered = filtered.filter((item) => 
        item.resource.calendar
      );
    }
  
    if (filters.login === 'active') {
      filtered = filtered.filter((item) => 
        item.user.last_login && isLastLoginWithin7Days(item.user.last_login)
      );
    }
  
    if (filters.login === 'inactive') {
      filtered = filtered.filter((item) => 
        item.user.last_login && !isLastLoginWithin7Days(item.user.last_login)
      );
    }
  
    if (filters.login === 'unknown') {
      filtered = filtered.filter((item) => 
        !item.user.last_login
      );
    }
  
    if (search.trim() !== "") {
      filtered = filtered.filter((item) =>
        item.user.first_name.toLowerCase().includes(search.toLowerCase()) ||  item.user.username.toLowerCase().includes(search.toLowerCase())
      );
    }
  
    setFilteredData(filtered);
  }, [search, filters, isChecked, drivers, inactiveDrivers]);
  
  return (
    <React.Fragment>
      <div className="custom-container">

        <div className="button-bar">
          <h2 className='text-left font-bold'>Drivers</h2>

          <div className="download-upload-buttons">
            <div style={{ marginRight: 30 }}>
              <button className='btn-hover' onClick={downloadDriverList}>
                <div className='row'>
                  <div style={{ height: '0.8rem' }}>
                    <IconDownload className="h-100" />
                  </div>
                  <div style={{ marginLeft: 5 }}>Download Driver List</div>
                </div>
              </button>
            </div>

            <div>
              <button className='btn-hover' onClick={handleButtonClick}>
                <div className='row'>
                  <div style={{ height: '0.8rem' }}>
                    <IconUpload className="h-100" />
                  </div>
                  <div style={{ marginLeft: 5 }}>Upload Driver List</div>
                </div>
              </button>
              <input
                type="file"
                accept=".xls,.xlsx"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onFileUpload}
              />
            </div>
          </div>
        </div>

        <div className='mt-3 mb-3'>
          <button
            className="btn rounded shadow-sm d-flex flex-row align-items-center p-2"
            style={{ backgroundColor: '#28a745' }}
            onClick={() => setShowModal(true)}
          >
            <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', color: 'white', backgroundColor: '#28a745' }}>+ Add</span>
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className='align-items-center search-input'>
            <div className='mb-2'>
              <input
                placeholder="Search..."
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className='filter-group' >
              {
                filtersConfig.map(({ id, key, label, options }) => (
                  <select
                    key={id}
                    id={id}
                    className="form-control"
                    value={filters[key]}
                    onChange={(e) => handleFilterChange(key, e.target.value)}
                  >
                    <option value="">
                      {label}
                    </option>
                    {options.map((opt, index) => (
                      <option key={index} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                ))
              }
              <div className="filter-toggle">
                <input
                  type="checkbox"
                  id="unsafe-filter"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <label htmlFor="unsafe-filter">Show inactive drivers</label>
              </div>
            </div>
          </div>
        </div>

        <LoadMask loading={loading} light>
          <div className='main-container'>
            <div className="dashboard-grid">

              <div className="summary-card">
                <div className="summary-title">Total Drivers</div>
                <div className="summary-value">{stats.totalDrivers}</div>
                <div className="summary-label">Active Drivers</div>
              </div>

              <div className="summary-card">
                <div className="summary-title">Assigned Today</div>
                <div className="summary-value">{stats.totalActive}</div>
                <div className="summary-label">Scheduled for today</div>
              </div>

              <div className="summary-card" style={{cursor:'pointer'}} onClick={()=>handleFilterChange('schedule','unassigned')}>
                <div className="summary-title">Unassigned</div>
                <div className="summary-value">{stats.totalUnassigned}</div>
                <div className="summary-label">Without Schedule</div>
              </div>

              <div className="summary-card">
                <div className="summary-title">Most Active Station</div>
                <div className="summary-value">{stats.mostActive}</div>
                <div className="summary-label">{`${stats.activeDrivers} drivers`}</div>
              </div>

            </div>

            <h3 className='font-bold text-left mt-5 mb-5'>Driver Directory</h3>
            {
              filteredData.length > 0 && filteredData.map(item => (
                <div className="driver-card" style={{ borderLeft: `4px solid ${item.department ? item.department.color : 'black'}` }} key={item.id}>
                  <div className="driver-header">
                    <div className="driver-name">
                      {getFullName(item.user.first_name, item.user.last_name)}
                      {
                        item.device_type && (
                          <div style={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
                            {item.device_type === 'ios' ? <IosIcon className='h-100' /> : <AndroidIcon className='h-100' />}
                          </div>
                        )
                      }
                    </div>
                    <span className="station-badge" style={{ backgroundColor: item.department ? item.department.color : 'black' }}>{item.station ? item.station.name : 'Unassigned'}</span>
                  </div>

                  <div className="driver-meta">
                    <div className="driver-meta-item">
                      <span className="meta-label">ID</span>
                      <span className="meta-value">{item.user.username}</span>
                    </div>
                    <div className="driver-meta-item">
                      <span className="meta-label">Last Login</span>
                      <span className="meta-value">{item.user.last_login ? moment.utc(item.user.last_login).local().format('YYYY-MM-DD HH:mm') : 'Unknown'}</span>
                    </div>
                    <div className="driver-meta-item">
                      <span className="meta-label">Schedule</span>
                      <span className="meta-value">{(item.resource && item.resource.calendar) ? item.resource.calendar.name : 'Unassigned'}</span>
                    </div>
                    <div className="driver-meta-item">
                      <span className="meta-label">Status</span>
                      <span className="meta-value">
                        <span className="status-badge"
                          style={{ backgroundColor: item.active === 1 ? 'green' : 'red' }}></span>{item.active === 1 ? 'Active' : 'Inactive'}</span>
                    </div>
                    <div className="driver-meta-item">
                      <div className="meta-label">Phone</div>
                      <div className="meta-value">{item.mobile_phone ? item.mobile_phone : 'Unknown'}</div>
                    </div>
                    <div className="driver-meta-item">
                      <div className="meta-label">Email</div>
                      <div className="meta-value">{item.work_email ? item.work_email : 'Unknown'}</div>
                    </div>
                  </div>
                  <div className="action-group" style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                    <button className="btn d-flex align-items-center p-0 me-3" onClick={() => { setSelectedItem(item); setShowModal(true); }}>
                      <div style={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
                        <IconWatch className='h-100' />
                      </div>
                      <span className="ml-1">View</span>
                    </button>
                    <button className="btn d-flex align-items-center p-0 me-3" onClick={() => { setSelectedItem(item), setShowDeleteModal(true) }}>

                      {item.active === 1 ?
                        <div className='deleteButton'>
                          <ArchiveIcon className='h-100' />
                        </div>
                        :
                        <div className='activateButton'>
                          <RotateArrow className='h-100' />
                        </div>
                      }
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        </LoadMask>

      </div>

      <ModalDriverForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        initialValues={selectedItem ? selectedItem : null}
        showComfirmation={showConfirmationModal}
        title={selectedItem ? 'Edit Driver' : 'Add Driver'}
        jobs={jobs}
        schedules={schedules}
        getDrivers={getDrivers}
      />

      <ConfirmationModal
        showModal={showDeleteModal}
        handleCloseModal={handleCloseDeleteModal}
        item={selectedItem}
        inactivate={isChecked}
        user={selectedItem ? selectedItem : null}
        getDrivers={getDrivers}
        setInactiveDrivers={setInactiveDrivers}
        setDrivers={setDrivers}
        setFilteredData={setFilteredData}
      />

    </React.Fragment>


  )
}
